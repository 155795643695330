import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import styles from './DocumentStep.module.scss'
import { ReactSVG } from "react-svg";
import docIcon from 'assets/icons/document.svg'
import DropdownInput from 'components/UI/DropdownInput/DropdownInput'
import CustomInput from 'components/UI/Input/Input'
import Form from 'pages/VisaStepsPage/ui/Form/Form';
import Text from 'components/UI/Text/Text';
import CustomDatePicker from 'components/UI/CustomDatePicker/CustomDatePicker';
import CustomCheckbox from 'components/UI/Checkbox/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from 'store/slices/visaSteps';
import { useFormSubmitted } from 'pages/VisaStepsPage/hooks';
import { selectInfo } from 'store/selectors/visaSteps';
import { validate } from './validate';

const documentTypesRussia = [
    {value: 'FOREIGN', label: 'Загранпаспорт'},
]

const documentTypes = [
    // {value: 'DIPLOMATIC', label: 'Дипломатический паспорт'},
    {value: 'RESIDENCEPERMIT', label: 'Вид на жительство'},
    // {value: 'PASSPORT', label: 'Иностранный документ'},
    // {value: 'SERVICE_PASSPORT', label: 'Служебный паспорт'},
    {value: 'FOREIGN_NATIONAL_PASSPORT', label: 'Заграничный национальный паспорт'}
]


const DocumentStep = () => {
    const info = useSelector(selectInfo)
    const formik = useFormik({
        initialValues: {
            documentType: info?.documentType || "FOREIGN",
            documentNumber: info?.documentNumber || '',
            expiryDate: info?.expiryDate || '',
            skipFields: info?.skipFields || false,
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: (values) => validate(values, values.skipFields, info.citizenship.code),
    });

    const dispatch = useDispatch()

    useFormSubmitted(formik)

    useEffect(() => {
        dispatch(setInfo(formik.values))
    }, [formik.values])

    return (
        <Form className={styles.form} >
            <div className={styles.title}>
                <ReactSVG src={docIcon} />
                <Text variant='body16' className={styles.titleText}>Документ</Text>
            </div>
            <div className={styles.inputs}>
                <DropdownInput
                    label={"Тип документа"}
                    //value={formik.values.documentType}
                    onChange={(val) => formik.setFieldValue('documentType', val)}
                    error={formik.errors.documentType}
                    options={info?.citizenship?.code === 'RU' ? documentTypesRussia : documentTypes}
                    disabled={info?.citizenship?.code === 'RU'}
                    defaultValue={"FOREIGN"}
                    value={
                        info?.citizenship?.code === "RU"
                            ? "FOREIGN"
                            : formik.values.documentType
                    }
                />
                <CustomInput
                    placeholder="Номер документа"
                    classes={styles.form__field_text}
                    container={styles.form__field_textContainer}
                    value={formik.values.documentNumber}
                    onChange={(e) => formik.setFieldValue('documentNumber', e)}
                    error={formik.errors.documentNumber}
                />
                <CustomDatePicker
                    label="Срок действия"
                    value={formik.values.expiryDate}
                    onChange={(date) => formik.setFieldValue('expiryDate', date)}
                    error={formik.errors.expiryDate}
                />
            </div>
            <CustomCheckbox
                text={'Пропустить ввод паспортных данных'}
                checked={formik.values.skipFields}
                onChange={(e) => formik.setFieldValue('skipFields', e.target.checked)}
            />
        </Form>
    )
}

export default DocumentStep;
