import {Select} from "antd";
import style from "./VisaDropdownCountry.module.scss";
import {ReactComponent as ArrowIcon} from "assets/icons/dropdown_arrow.svg";
import classNames from "classnames";
import Spinner from "components/UI/Spinner/Spinner";
import {useGetCountriesQuery} from "services/visaSteps";
import * as Flags from "country-flag-icons/react/3x2";
import {useDebounce} from "hooks/useDebounce";
import { memo, useState } from "react";

const convertDataToOptions = ({data}) => data?.data?.map(country => ({
    label: country.name_ru,
    value: country.code,
}))

const VisaDropdownCountry = ({
                                 placeholder,
                                 label,
                                 onChange,
                                 name,
                                 classes,
                                 containerClasses,
                                 value,
                                 error,
                                 disabled,
                                 isLoading,
                                 getFullInfo,
                                 onSearch = () => {
                                 },
                                 setOptions
                             }) => {
    const [searchedValue, setSearchedValue] = useState('');
    const debouncedInputValue = useDebounce(searchedValue, 300);
    let options = useGetCountriesQuery(debouncedInputValue);

    if (label === 'Поездка в страну' && (searchedValue === '' || setOptions.some(item => item.name_ru === searchedValue))) {
        options = {
            data: {
                data: setOptions
            }
        }
    }
    const getFullValue = (e) => {
        if (!options?.data?.data) return {};
        return ({ ...options.data.data.find(opt => opt.code === e.value), ...e });
    };

    const Icon = Flags[value?.value];

    return (
        <div className={classNames(style.dropdown_item_container, containerClasses)}>
            <Select
                labelInValue
                popupClassName={style.remove}
                onSearch={(e) => {
                    setSearchedValue(e);
                    onSearch(e);
                }}
                showSearch
                placeholder={placeholder}
                className={classNames(style.dropdown_input, classes, {
                    [style["dropdown_input_with_label"]]: label,
                    [style["dropdown_input_error"]]: error,
                })}
                options={convertDataToOptions(options)}
                suffixIcon={<ArrowIcon/>}
                optionLabelProp="label"
                optionRender={(item) => {
                    const Icon = Flags[item.value];

                    return (
                        <div className={style.dropdown_input__option} style={{background: "none"}}>
                            <div className={classNames([style.dropdown_input__option__label, style.selectedContent])}>
                                {Icon && <Icon className={style.flag}/>}
                                <span>{item.label}</span>
                            </div>
                            <div className={style.dropdown_input__option__value}>
                                {item.value}
                            </div>
                        </div>
                    );
                }}
                onChange={(e) => {
                    onChange(e);
                    getFullInfo?.(getFullValue(e));
                    setSearchedValue(e.label);
                }}
                name={name}
                value={
                    value && {
                        label: (
                            <div className={style.selectedValue}>
                                <div className={style.selectedContent}>
                                    {Icon && <Icon className={style.flag}/>}
                                    <span>{value.label}</span>
                                </div>
                            </div>
                        ),
                        value: value,
                    }
                }
                disabled={disabled}
                notFoundContent={options?.isFetching ? <Spinner/> : !options?.data?.length ? 'Ничего не найдено' : null}
                filterOption={false}
            />
            {label && (
                <span className={style.dropdown_input_label}>{label}</span>
            )}
            <span className={style.error_text}>{error || ''}</span>
        </div>
    );
};

export default memo(VisaDropdownCountry);
