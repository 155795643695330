export const validate = (values) => {
    const errors = {};

    if (!values.citizenship) {
        errors.citizenship = "Гражданство обязательно";
    }

    if (!values.countryTo) {
        errors.countryTo = "Страна поездки обязательна";
    }

    if (values.citizenship?.value === values.countryTo?.value) {
        errors.citizenship = "Гражданство и страна поездки не должны совпадать";
        errors.countryTo = "Гражданство и страна поездки не должны совпадать";
    }
    
    return errors;
};