import { useEffect, useRef, useState } from "react";
import style from "./CustomDatePicker.module.scss";
import classNames from "classnames";

const placeholder = "дд.мм.гггг";

const CustomDatePicker = ({
    error,
    value,
    classes,
    onChange,
    disabled,
    label,
    isAdaptiveLabel,
    hasValue,
}) => {
    const [dateValue, setDateValue] = useState(value);
    const [placeholderValue, setPlaceholderValue] = useState(value ? "" : "дд.мм.гггг");
    const inputRef = useRef(null);
    
    useEffect( () => {
        setDateValue(value);
        if ( !value ){
            setPlaceholderValue("дд.мм.гггг");
        } else {
            setPlaceholderValue(placeholder.substring(value.length, placeholder.length));
        }
    }, [value] );

    const onChangeDateValue = (event) => {
        const { value } = event.target;
        let newValue = value;
        if (value.length > 10) {
            return;
        }
        if (
            (value.length === 3 || value.length === 6) &&
            value[value.length - 1] !== "."
        ) {
            newValue =
                value.substring(0, value.length - 1) +
                "." +
                value[value.length - 1];
        }
        setDateValue(newValue);
        setPlaceholderValue(
            placeholder.substring(newValue.length, placeholder.length)
        );
        onChange && onChange(newValue);
    };

    return (
        <div className={classNames(style.datepicker, classes)}>
            <span className={classNames([style.datepicker_field_name, {[style.adaptive]: isAdaptiveLabel}])}>
                {label ? label : "Дата рождения"}
            </span>
            <div
                className={style.datepicker_values}
                onClick={() => inputRef.current.focus()}
            >
                {

                }
                <span className={style.datepicker_values_value}>
                    {dateValue}
                </span>
                <span className={style.datepicker_values_placeholder}>
                    {placeholderValue}
                </span>
            </div>
            <input
                className={classNames(style.datepicker_input, {
                    [style["datepicker_input_error"]]: error,
                    [style["datepicker_input_with_text"]]: dateValue,
                })}
                type="text"
                ref={inputRef}
                value={dateValue}
                onChange={onChangeDateValue}
                disabled={disabled}
            />
            {error && <p className={style.error_text}>{error}</p>}
        </div>
    );
};

export default CustomDatePicker;
