export const BASE_URL =
    process.env.REACT_APP_BACK_URL ??
    "https://admin.airsurfer.dev-2-tech.ru/api/v1/";
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const BASE_URL_FRONT =
    process.env.REACT_APP_FRONT_URL ?? "https://airsurfer.dev-2-tech.ru/";

export const BASE_ADMIN_URL = "https://admin.airsurfer.dev-2-tech.ru/";


export const ROUTES = {
    STEPS: '/visa/:params',
    STEPS_ID: '/visa/:params/:id',
    VISA: '/visa'
}
