import styles from "../BookingPayment/BookingPayment.module.scss";
import {useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import {selectLang} from "store/selectors/header";
import FAQ from "../BookingPayment/FAQ/FAQ";
import Payment from "./Payment/Payment";
import {useGetVisaOrderByIdQuery} from "../../services/orders";

const VisaPayment = () => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const orderId = params.id;
    const {data: ordersQuery} = useGetVisaOrderByIdQuery(
        {id: orderId},
        {skip: !orderId}
    );

    const currentLang = useSelector(selectLang);
    const {countries, countries_en} = useSelector(
        (state) => state.catalog.catalog
    );

    const [paymentError, setPaymentError] = useState(null);

    if (paymentError === null && searchParams.get("payment") === "failed") {
        setPaymentError(searchParams.get("message"));
    }

    const month = {
        "01": "янв",
        "02": "фев",
        "03": "мар",
        "04": "апр",
        "05": "май",
        "06": "июн",
        "07": "июл",
        "08": "авг",
        "09": "сен",
        10: "окт",
        11: "ноя",
        12: "дек",
    };

    const passengers = ordersQuery?.data.passengers;
    const itineraryReceiptAmount = passengers?.length;

    //let passengersContent = [];
    /*if (passengers && passengers.length)
        passengersContent = passengers.map(function (elem) {
            return {
                birthDate: elem.birthday,
                citizenship: citizenship ? citizenship[elem.country] : "",
                documentNumber: elem.document_number,
                name: elem.lastname + " " + elem.firstname,
                sex: elem.gender === "female" ? "Женский" : "Мужской",
                ticketType: passengerTypes[elem.type],
            };
        });
*/


    const countryTo = ordersQuery?.data.country_to;
    const citizenship = ordersQuery?.data.citizenship;
    const price = ordersQuery?.data.price;
    const arrival_date = ordersQuery?.data.arrival_date;
    const departure_date = ordersQuery?.data.departure_date;





    const orderData = ordersQuery?.data;

    return (
        <section className={styles.bookingSuccess}>
            <div className="container">
                <div className={styles.bookingSuccess__wrapper}>
                    <Payment
                        orderId={orderId}
                        orderData={orderData}

                        countryTo={countryTo}
                        citizenship={citizenship}
                        price={price}
                        arrival_date={arrival_date}
                        departure_date={departure_date}
                        passangerCount={itineraryReceiptAmount}
                        paymentError={paymentError}

                    />
                </div>
            </div>
            <FAQ title={"Вопросы и ответы"}/>
        </section>
    );
};

export default VisaPayment;
