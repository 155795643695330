import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import SingleBreadCrumb from 'components/UI/SingleBreadCrumb/SingleBreadCrumb'
import Title from 'components/UI/Title/Title'
import styles from './Wrapper.module.scss'
import classNames from 'classnames';
import PaymentAside from '../PaymentAside/PaymentAside';
import ProgressTracker from 'components/UI/ProgressTracker/ProgressTracker'
import { selectFinishedItemsAmount, selectInfo, selectStepData } from "store/selectors/visaSteps";
import { useDispatch, useSelector } from "react-redux";
import Header from '../Header/Header';
import { back, goToStep } from 'store/slices/visaSteps';

const progressTitles = ['Подробности поездки', 'Данные пассажиров', 'Детали заказа']

const Wrapper = ({ paymentAsideType, children }) => {
    const dispatch = useDispatch()
    const stepData = useSelector(selectStepData);
    const { stay_of, countryTo } = useSelector(selectInfo);
    const finishedItemsAmount = useSelector(selectFinishedItemsAmount);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [finishedItemsAmount])

    return (
        <div className={styles.page}>
            <div className={classNames(["container", styles.container])}>
                <SingleBreadCrumb
                    title={"Назад"}
                    onClick={() => finishedItemsAmount ? dispatch(back()) : navigate(-1)}
                />
                <Title>Заявка на получение электронной визы в страну {countryTo?.label}{finishedItemsAmount && stay_of ? ', ' + stay_of : ''}</Title>
                {(
                    stepData.currentStepNumber ?
                        <>
                            <ProgressTracker
                                onStepClick={(i) => i < stepData.currentStepNumber && dispatch(goToStep(i))}
                                className={styles.progress}
                                titles={progressTitles}
                                {...stepData}
                            />
                            <Header />
                        </> :
                        <></>
                )}
                <div className={classNames([styles.wrapper, { [styles.info]: !stepData.currentStepNumber }])}>
                    <div className={styles.children}>
                        {children}
                    </div>
                    <PaymentAside type={paymentAsideType} />
                </div>
            </div>
        </div>
    )
}

export default Wrapper
