import { createSelector } from "@reduxjs/toolkit";
import { getTariffsFlight } from "utils/tariffService";

const selectCatalog = (state) => state.catalog;

export const selectCurrencies = createSelector(
    selectCatalog,
    (catalog) => catalog.currencies
);

export const selectTariffs= createSelector(selectCatalog, (catalog) => {
    return catalog.tariffs;
});

export const selectVisaCountries= createSelector(selectCatalog, (catalog) => {
    return catalog.visaCountries;
});

export const selectCheepestTariff= createSelector(selectCatalog, (catalog) => {
    return getTariffsFlight(catalog.tariffs)[0];
});

export const selectCounties = createSelector(selectCatalog, (catalog) => {
    return catalog.counties;
});

// export const selectCurrentPrice = createSelector(

