import CustomButton from 'components/UI/CustomButton/CustomButton';
import Popup from 'components/UI/Popup/Popup';
import Text from 'components/UI/Text/Text';
import Title from 'components/UI/Title/Title';
import React from 'react'
import styles from './ProtectionInfoModal.module.scss'
import shield from 'assets/icons/fullShield.png'

const ProtectionInfoModal = ({ isOpen, setIsOpen }) => {
    const desc1 = [
        "Получите 100% возврат средств по вашей заявке, если она будет отклонена правительством, — без лишних вопросов!",
        "Мы автоматически вернем вам деньги, использованные при первоначальной оплате"
    ]

    const desc2 = [
        "Оплата услуги защита от отказа не подлежит возврату",
        "Правительство отклоняет заявки по своему усмотрению"
    ]

    return (
        <Popup
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            footer={
                <CustomButton
                    primary
                    fullWidth
                    onClick={() => setIsOpen(false)}
                >
                    Продолжить
                </CustomButton>
            }
            className={styles.modal}
        >
            <div className={styles.content}>
                <img src={shield} alt="shield" className={styles.img} />
                <Title Tag='h4' className={styles.title}>Защита от отказа</Title>
                <Text variant='body14' className={styles.subtitle}>Получите возврат средств с помощью услуги защиты от отказа</Text>
                {desc1.map(i => <Text key={i} variant='body14' className={styles.desc1}>{i}</Text>)}
                <div className={styles.line}></div>
                {desc2.map(i => <Text key={i} variant='body14' className={styles.desc2}>{i}</Text>)}
            </div>
        </Popup>
    )
}

export default ProtectionInfoModal
