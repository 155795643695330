import { baseApi } from "api";

export const ordersApi = baseApi({
    reducerPath: "ordersApi",
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: (page) => ({ url: `orders?page=${page}`, method: "GET" }),
            providesTags: ["Order"],
        }),
        getOrderById: builder.query({
            query: (params) => ({
                url: `orders/?id=${params.id}`,
                method: "GET",
            }),
            providesTags: ["Order"],
        }),
        postOrder: builder.mutation({
            query: (order) => ({ url: `orders`, method: "POST", body: order }),
            invalidatesTags: (_, error) => (error ? [] : ["Order"]),
        }),
        putOrder: builder.mutation({
            query: (order) => ({ url: `orders`, method: "PUT", body: order }),
            invalidatesTags: (_, error) => (error ? [] : ["Order"]),
        }),
        postOrderPrice: builder.mutation({
            query: (order) => ({
                url: `orders/price`,
                method: "POST",
                body: order,
            }),
            invalidatesTags: (_, error) => (error ? [] : ["Order"]),
        }),
        getVisaOrderById: builder.query({
            query: (params) => ({
                url: `visa/order/?id=${params.id}`,
                method: "GET",
            }),
            providesTags: ["Order"],
        }),
    }),
});

export const {
    useGetOrdersQuery,
    useGetOrderByIdQuery,
    usePostOrderMutation,
    usePutOrderMutation,
    usePostOrderPriceMutation,
    useGetVisaOrderByIdQuery,
} = ordersApi;
