import { isValidDate } from "utils/dateFn";

const isDateWithinRange = (date) => {
    const [day, month, year] = date.split(".").map(Number);

    if (!isValidDate(day, month, year)) return false;

    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const oldestDate = new Date();
    oldestDate.setFullYear(currentDate.getFullYear() - 150);

    return birthDate >= oldestDate && birthDate <= currentDate;
};

export const validate = (values) => {
    const errors = {};

    errors.applicants = values.applicants.map((applicant) => {
        const applicantErrors = {};

        if (!applicant.firstName) {
            applicantErrors.firstName = "Имя обязательно";
        } else if (!/^[A-Za-z\s]+$/.test(applicant.firstName) || applicant.firstName !== applicant.firstName.toUpperCase()) {
            applicantErrors.firstName = "Имя должно быть на латинице, например IVAN IVANOV";
        }

        if (!applicant.lastName) {
            applicantErrors.lastName = "Фамилия обязательна";
        } else if (!/^[A-Za-z\s]+$/.test(applicant.lastName) || applicant.lastName !== applicant.lastName.toUpperCase()) {
            applicantErrors.lastName = "Фамилия должна быть на латинице, например IVAN IVANOV";
        }

        if (!applicant.birthDate) {
            applicantErrors.birthDate = "Дата рождения обязательна";
        } else if (!isDateWithinRange(applicant.birthDate)) {
            applicantErrors.birthDate = "Дата рождения должна быть корректной";
        }

        if (!applicant.birthCountry) {
            applicantErrors.birthCountry = "Страна рождения обязательна";
        }

        if (!applicant.residenceCountry) {
            applicantErrors.residenceCountry = "Страна проживания обязательна";
        }

        if (!applicant.gender) {
            applicantErrors.gender = "Пол обязателен";
        }

        return applicantErrors;
    });

    return errors;
};
