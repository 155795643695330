import InfoStep from './steps/InfoStep/InfoStep'
import TripDetailsStep from './steps/TripDetailsStep/TripDetailsStep'
import OrderDetailsStep from './steps/OrderDetailsStep/OrderDetailsStep'
import ApplicationProcessingStep from './steps/ApplicationProcessingStep/ApplicationProcessingStep'
import {useDispatch, useSelector} from "react-redux";
import { selectFinishedItemsAmount, selectInfo } from "store/selectors/visaSteps";
import React, {useEffect} from 'react';
import Wrapper from './ui/Wrapper/Wrapper';
import ApplicantStep from './steps/ApplicantStep/ApplicantStep'
import DocumentStep from './steps/DocumentStep/DocumentStep'
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { resetSteps } from 'store/slices/visaSteps';

const VisaStepsPage = () => {
    const finishedItemsAmount = useSelector(selectFinishedItemsAmount);
    const { countryTo, citizenship } = useSelector(selectInfo);
    const params = useParams()?.params?.split('-');
    const dispatch = useDispatch()

    useEffect(() => {
        if(params[0].toLowerCase() !== citizenship?.code.toLowerCase() || params[1].toLowerCase() !== countryTo?.code.toLowerCase()) dispatch(resetSteps())
    }, [])

    const Meta = () => <Helmet>
        <title>Электронная виза для граждан страны {citizenship?.name_ru || ''} в страну {countryTo?.name_ru || ''}.</title>
        <meta name="description" content={`Узнайте, как получить визу для ${citizenship} в ${countryTo}.`}/>
        <meta name="keywords" content={`виза, ${citizenship}, ${countryTo}, путешествия`} />
    </Helmet>

    if (!finishedItemsAmount) return (
        <>
            <Meta />
            <Wrapper paymentAsideType='info'>
                <InfoStep />
            </Wrapper>
        </>
    )

    const ApplicationStageStep = [
        TripDetailsStep,
        ApplicantStep,
        DocumentStep,
        ApplicationProcessingStep,
        OrderDetailsStep
    ][finishedItemsAmount - 1]


    return (
        <>
            <Meta/>
            <Wrapper
                paymentAsideType='result'
            >
                <ApplicationStageStep />
            </Wrapper>
        </>
    )
}

export default VisaStepsPage



