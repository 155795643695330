import React, { useLayoutEffect, useState } from 'react';
import { Tabs } from 'antd';
import style from "./Tabs.module.scss";
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from 'api/constants';

const TabsItems = ({ items, setPageNumber }) => {
  const [activeKey, setActiveKey] = useState("1");
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    const key = location.pathname === ROUTES.VISA ? '2' : '1'
    setActiveKey(key);
    setPageNumber(Number(key) - 1);
  });

  const onChange = (key) => {
    setActiveKey(key);
    setPageNumber(Number(key) - 1);
    navigate(key === '2' ? ROUTES.VISA : '/');
  };

  return (
    <Tabs
      className={style.tabs_items}
      type="card"
      items={items}
      onChange={onChange}
      activeKey={activeKey}
    />
  );
};

export default TabsItems;
