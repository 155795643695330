import { isDateInTheFuture, isValidDate } from 'utils/dateFn';

const isArrivalBeforeDeparture = (arrival, departure) => {
    const [arrivalDay, arrivalMonth, arrivalYear] = arrival.split('.').map(Number);
    const [departureDay, departureMonth, departureYear] = departure.split('.').map(Number);

    if (
        !isValidDate(arrivalDay, arrivalMonth, arrivalYear) ||
        !isValidDate(departureDay, departureMonth, departureYear)
    ) {
        return false;
    }

    const arrivalDate = new Date(arrivalYear, arrivalMonth - 1, arrivalDay);
    const departureDate = new Date(departureYear, departureMonth - 1, departureDay);

    return arrivalDate < departureDate;
};

export const validate = (values) => {
    const errors = {};

    if (!values.arrivalDate) {
        errors.arrivalDate = 'Дата прибытия обязательна';
    } else if (!isDateInTheFuture(values.arrivalDate)) {
        errors.arrivalDate = 'Дата прибытия должна быть корректной';
    }

    if (!values.departureDate) {
        errors.departureDate = 'Дата отбытия обязательна';
    } else if (!isDateInTheFuture(values.departureDate)) {
        errors.departureDate = 'Дата отбытия должна быть корректной';
    }

    if (values.arrivalDate && values.departureDate && (!errors.arrivalDate && !errors.departureDate)) {
        if (!isArrivalBeforeDeparture(values.arrivalDate, values.departureDate)) {
            errors.arrivalDate = 'Дата прибытия должна быть раньше даты отбытия';
            errors.departureDate = 'Дата отбытия должна быть позже даты прибытия';
        }
    }

    if (!values.email) {
        errors.email = 'Адрес электронной почты обязателен';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Неверный формат адреса электронной почты';
    }

    return errors;
}