import { Input } from "antd";
import style from "./Input.module.scss";
import classNames from "classnames";
import { MaskedInput } from "antd-mask-input";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import eye from "../../../assets/icons/eye.svg";
import openedEye from "../../../assets/icons/openedEye.svg";
import { CardInput } from "../CardInput";

const CustomInput = ({
    password,
    icon,
    error,
    searchError,
    placeholder,
    blocked,
    onChange,
    name,
    classes,
    container,
    value,
    success,
    ref,
    mask,
    isCard,
    label,
    isAdaptiveLabel,
    type
}) => {
    const onInputChange = (event) => {
        const newValue = event.target.value;
        onChange && onChange(newValue);
    };

    const [isPassword, setIsPassword] = useState(true);

    const handlePassword = () => {
        setIsPassword((prev) => !prev);
    };

    if (mask) {
        return (
            <div
                className={classNames(style.custom_input_container, container)}
            >
                <MaskedInput
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    ref={ref}
                    onChange={(event) => onInputChange(event)}
                    className={classNames(style.custom_input, classes, {
                        [style["custom_input_error"]]: error,
                        [style["custom_input_search_error"]]: searchError,
                        [style["custom_input_blocked"]]: blocked,
                        [style["custom_input_with_text"]]: value || label,
                        [style["custom_input_with_icon"]]: icon,
                        [style["custom_input_with_success"]]: success,
                    })}
                    disabled={blocked}
                    suffix={icon}
                    mask={mask}
                />
                {(label || value) && (
                    <span className={classNames(style.custom_input_placeholder, { [style.adaptive]: isAdaptiveLabel })}>
                        {label || placeholder}
                    </span>
                )}
                {error && <span className={style.error_text}>{error}</span>}
                {success && (
                    <span className={style.success_text}>{success}</span>
                )}
                {searchError && (
                    <p className={style.search_error_text}>{searchError}</p>
                )}
            </div>
        );
    }

    if (password) {
        return (
            <div
                className={classNames(style.custom_input_container, container)}
            >
                <Input
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    ref={ref}
                    type={isPassword ? "password" : "text"}
                    onChange={(event) => onInputChange(event)}
                    className={classNames(style.custom_input, classes, {
                        [style["custom_input_error"]]: error,
                        [style["custom_input_search_error"]]: searchError,
                        [style["custom_input_blocked"]]: blocked,
                        [style["custom_input_with_text"]]: value || label,
                        [style["custom_input_with_icon"]]: icon,
                        [style["custom_input_with_success"]]: success,
                    })}
                    disabled={blocked}
                    suffix={icon}
                />
                {(value || label) && (
                    <span className={classNames(style.custom_input_placeholder, { [style.adaptive]: isAdaptiveLabel })}>
                        {label || placeholder}
                    </span>
                )}
                {isPassword === true ? (
                    <div
                        onClick={handlePassword}
                        className={style.custom_input_password}
                    >
                        <ReactSVG src={eye} />
                    </div>
                ) : (
                    <div
                        onClick={handlePassword}
                        className={style.custom_input_password}
                    >
                        <ReactSVG src={openedEye} />
                    </div>
                )}
                {error && <span className={style.error_text}>{error}</span>}
                {success && (
                    <span className={style.success_text}>{success}</span>
                )}
                {searchError && (
                    <span className={style.search_error_text}>
                        {searchError}
                    </span>
                )}
            </div>
        );
    }

    return (
        <div className={classNames(style.custom_input_container, container)}>
            {isCard ? (
                <CardInput
                    placeholder={placeholder}
                    onChange={(newValue) => onChange(newValue)}
                    className={classNames(style.custom_input, classes, {
                        [style["custom_input_error"]]: error,
                        [style["custom_input_search_error"]]: searchError,
                        [style["custom_input_blocked"]]: blocked,
                        [style["custom_input_with_text"]]: value || label,
                        [style["custom_input_with_icon"]]: icon,
                        [style["custom_input_with_success"]]: success,
                    })}
                    value={value}
                    disabled={blocked}
                />
            ) : (
                <Input
                    type={type || 'text'}
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    ref={ref}
                    onChange={(event) => onInputChange(event)}
                    className={classNames(style.custom_input, classes, {
                        [style["custom_input_error"]]: error,
                        [style["custom_input_search_error"]]: searchError,
                        [style["custom_input_blocked"]]: blocked,
                        [style["custom_input_with_text"]]: value || label,
                        [style["custom_input_with_icon"]]: icon,
                        [style["custom_input_with_success"]]: success,
                    })}
                    disabled={blocked}
                    suffix={icon}
                />
            )}

            {(value || label) && (
                <span className={classNames(style.custom_input_placeholder, { [style.adaptive]: isAdaptiveLabel })}>
                    {label || placeholder}
                </span>
            )}
            {error && <p className={style.error_text}>{error}</p>}
            {success && <span className={style.success_text}>{success}</span>}
            {searchError && (
                <span className={style.search_error_text}>{searchError}</span>
            )}
        </div>
    );
};

export default CustomInput;
