import styles from './Card.module.scss'
import classNames from 'classnames';

const Card = ({ imgSrc, imgAlt, className, children, rightHeaderElement, title, subtitle, subtitleNote }) => {
    return <div className={classNames([styles.wrapper, className])}>
        <div className={styles.header}>
            <img className={styles.src} src={imgSrc} alt={imgAlt} />
            {rightHeaderElement}
        </div>
        <div className={styles.body}>
            <div className={styles.bodyHeader}>
                <p className={styles.title}>{title}</p>
                <p className={styles.subtitle}>{subtitle} <span>{subtitleNote}</span></p>
            </div>
            {children}
        </div>
    </div>
}

export default Card