import { BASE_URL_FRONT } from "api/constants"

const useMainPageTrail = (pageNumber) => {
    return [
        {
            title: [
                'Как работает ',
                'Airsurfer'
            ],
            items: [
                {
                    title: 'Выберите нужный маршрут и введите данные пассажиров',
                    imgName: 'plane',
                },
                {
                    title: 'Оплатите сервисный сбор',
                    imgName: 'phone',
                    text: 'Полную стоимость билета оплачивать не нужно'
                },
                {
                    title: 'Получите бронь билета',
                    imgName: 'stat',
                    text: <>
                        C подтвержденным PNR-кодом от авиакомпании сразу после оплаты. Маршрутная квитанция действительна 3 дня, 5-7 дней или 10-14 дней на выбор.
                        <a
                            href={BASE_URL_FRONT + "AirSurfer_Flight_Example.pdf"}
                            target="_blank"
                            rel="noopener noreferrer">
                            <span> Пример брони в PDF</span>
                        </a>
                    </>
                }
            ]
        },
        {
            title: [
                'Получение визы в ',
                'пару кликов'
            ],
            items: [
                {
                    title: 'Заполните форму на получение визы',
                    imgName: 'phone',
                    text: 'Заполнит заявление с данными о поездке и оплатите заказ'
                },
                {
                    title: 'Получите готовый документ по электронной почте',
                    imgName: 'stat',
                    text: 'Мы подготовим и отправим вашу визу онлайн.  Посольство посещать не нужно'
                },
                {
                    title: 'Предъявите электронную визу по прибытии в страну назначения',
                    imgName: 'plane',
                }
            ]
        }
    ][pageNumber]
}

export default useMainPageTrail

