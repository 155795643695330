import React from "react";
import PropTypes from "prop-types";
import styles from "./Title.module.scss";
import classNames from "classnames";

const Title = ({ children, Tag = "h2", className }) => {
  return <Tag className={classNames([styles[Tag], className])}>{children}</Tag>;
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  Tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "p"]),
};

export default Title;