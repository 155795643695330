import { ReactSVG } from 'react-svg'
import styles from './InfoItem.module.scss'
import classNames from 'classnames'
import { formatNumber } from 'utils/numberFn'

const InfoItem = ({ name, desc, note, isLink, href, iconSrc, price, isSmall, currentCurrency }) => {
    return <div className={styles.wrapper}>
        {iconSrc && <div className={classNames([styles.icon, { [styles.small]: isSmall }])}>
            <ReactSVG src={iconSrc} />
        </div>}
        <div className={styles.content}>
            {name && <p className={styles.name}>
                {name}
            </p>}
            <div className={styles.info}>
                <div>
                    {
                        !isLink || !desc
                            ? <p className={styles.title}>
                                {desc || '-'}
                            </p>
                            : <a target='_blank' href={href} className={styles.link}>
                                {desc || '-'}
                            </a>
                    }
                </div>
                {price && <p className={styles.price}>{formatNumber(price)} {currentCurrency?.symbol}</p>}
            </div>
            {note && <p className={styles.note}>
                {note}
            </p>}
        </div>

    </div>
}

export default InfoItem