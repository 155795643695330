

import Insurance from "../../assets/icons/insurance-big.svg";
import CheckMark from "../../assets/icons/checkmark.svg";
import Clock from "../../assets/icons/clock.svg";
import Lightning from "../../assets/icons/lightning.svg";
import House from "../../assets/icons/house.svg";
import Warning from "../../assets/icons/warning-triangle.svg";
import { ReactSVG } from "react-svg";
import { BASE_URL_FRONT } from "../../api/constants";
import styles from './ReliablySection.module.scss'

const reliablyContent = [
    {
        title: [
            'Бронируйте авиабилеты без оплаты'
        ],
        items: [
            {
                img: CheckMark,
                title: "Официальная бронь авиабилета",
                text: (
                    <>
                        Бронь авиабилета можно проверить на сайте авиакомпании после
                        оплаты заказа.
                        <p>
                            <a
                                className={styles.reliably__item_link}
                                href={"/faq"}
                                target={"_blank"}
                            >
                                Подробнее, как проверить.
                            </a>
                        </p>
                    </>
                ),
                key: 0,
            },
            {
                img: Clock,
                title: "Срок действия - до 28 дней",
                text: "Бронь авиабилета действительна 3 дня, 5-7 дней, 10-14 дней или 20-28 дней на выбор",
                key: 1,
            },
            {
                img: Lightning,
                title: "Мгновенное подтверждение",
                text: (
                    <>
                        Получите маршрутную квитанцию с подтвержденным PNR-кодом от
                        авиакомпании сразу после оплаты.
                        <a
                            className={styles.reliably__item_link}
                            href={BASE_URL_FRONT + "AirSurfer_Flight_Example.pdf"}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {" "}
                            Пример маршрутной квитанции
                        </a>
                    </>
                ),
                key: 2,
            },
            {
                img: House,
                title: "Бронирование отелей",
                text: (
                    <>
                        Подтверждение проживания в гостинице,
                        <a
                            className={styles.reliably__item_link}
                            href={BASE_URL_FRONT + "AirSurfer_Hotel_Example.pdf"}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span> отеле</span>
                        </a>
                    </>
                ),
                key: 3,
            },
        ]
    },
    {
        title: [
            'С нами путешествия ',
            'надежны',
            '!'
        ],
        items: [
            {
                img: CheckMark,
                title: "Высокий процент одобрения",
                text: "Наш процент одобрения виз - 99,1%. Это отличный стабильный результат, который обеспечивает наш сервис",
                key: 0,
            },
            {
                img: Insurance,
                title: "Информация под защитой",
                text: "Ваши персональные данные защищены с помощью продвинутых средств безопасности",
                key: 1,
            },
            {
                img: Lightning,
                title: "Быстрое подтверждение",
                text: "Быстрое одобрение и получение визы вместе с нашим сервисом",
                key: 2,
            },
            {
                img: Warning,
                title: "Служба заботы 24/7",
                text: "Не волнуйтесь! Наша служба поддержки готова помочь 24 часа в сутки 7 дней в неделю",
                key: 3,
            },
        ]
    }
];

const useMainPageReliablySection = (pageNumber) => {
    return reliablyContent[pageNumber]
}

export default useMainPageReliablySection