import React from 'react'
import styles from './PopularDestinations.module.scss'
import img1 from 'assets/img/popular-destinations/img1.png'
import img2 from 'assets/img/popular-destinations/img2.png'
import img3 from 'assets/img/popular-destinations/img3.png'
import check from 'assets/icons/check-grey.svg'
import { ReactSVG } from 'react-svg'

const data = {
    title: ['Наиболее ', 'популярные', ' направления'],
    items: [
        {
            title: 'Сингапур',
            text: '13 942 заявления подано',
            imgName: img1,
        },
        {
            title: 'Индия',
            text: '7 011 заявлений подано',
            imgName: img2,
        },
        {
            title: 'Южная Корея',
            text: '5 800 заявлений подано',
            imgName: img3,
        }
    ]
}

const PopularDestinations = () => {
    const { title, items } = data
    return (
        <section className={styles.trail}>
            <div className="container">
                <div className={styles.trail__wrapper}>
                    <h2 className={styles.trail__title}>
                        {title.map((text, i) => <React.Fragment key={text}>{i % 2 ? <span>{text}</span> : text}</React.Fragment>)}
                    </h2>
                    <ul className={styles.trail__items}>
                        {items.map(({ title, imgName, text }, i) => (
                            <li className={styles.trail__item} key={title}>
                                <div className={styles.trail__item_top}>
                                    <p className={styles.trail__item_count}>{i + 1}</p>
                                    <h3 className={styles.trail__item_title}>
                                        {title}
                                    </h3>
                                    <div className={styles.content}>
                                        <ReactSVG src={check} className={styles.icon} />
                                        <p className={styles.trail__item_text}>
                                            {text}
                                        </p>
                                    </div>
                                </div>
                                <img
                                    className={styles.trail__item_img}
                                    src={imgName}
                                    alt={imgName}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default PopularDestinations