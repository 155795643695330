import { Modal } from "antd";
import style from "./Popup.module.scss";
import CloseIcon from "../../../assets/icons/close.svg";
import { ReactSVG } from "react-svg";
import { cloneElement, useRef, useState } from "react";
import clsx from "clsx";

const Popup = ({
    children,
    title,
    footer,
    open,
    onOk,
    onCancel,
    afterClose,
    isPartner,
    isFinished,
    destroyOnClose,
    className
}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const modalRef = useRef(null);

    const handleAfterClose = () => {
        if (isSuccess) {
            afterClose();
        }
        setIsSuccess(false);
    };

    const clonedChildren =
        children &&
        cloneElement(children, {
            onSuccess: () => {
                onCancel();
                setIsSuccess(true);
            },
        });

    return (
        <Modal
            destroyOnClose={destroyOnClose}
            className={clsx(style.custom_popup, className, {
                [style.custom_popup_partner]: isPartner,
            })}
            wrapClassName={isPartner && style.custom_popup_partner_wrap}
            title={title}
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            footer={footer}
            afterClose={handleAfterClose}
            closeIcon={isFinished ? <></> : <ReactSVG src={CloseIcon} />}
        >
            {clonedChildren}
        </Modal>
    );
};

export default Popup;
