import styles from "./Trail.module.scss"
import plane from "assets/icons/plane-trail.png"
import phone from "assets/icons/phone-trail.png"
import stat from "assets/icons/stat-trail.png"
import useMainPageTrail from "./useMainPageTrail";
import classNames from "classnames";
import React from "react";

const Trail = ({ pageNumber }) => {
    const { title, items } = useMainPageTrail(pageNumber)
    const imgs = {plane, phone, stat}
    
    return (
        <section className={styles.trail}>
            <div className="container">
                <div className={styles.trail__wrapper}>
                    <h2 className={styles.trail__title}>
                        {title.map((text, i) => <React.Fragment key={text}>{i % 2 ? <span>{text}</span> : text}</React.Fragment>)}
                    </h2>
                    <ul className={styles.trail__items}>
                        {items.map(({ title, imgName, text }, i) => (
                            <li key={title} className={classNames([styles.trail__item, {[styles.colored]: imgName === 'stat'}])}>
                                <div className={styles.trail__item_top}>
                                    <p className={styles.trail__item_count}>{i + 1}</p>
                                    <h3 className={styles.trail__item_title}>
                                        {title}
                                    </h3>
                                    {text && <p className={styles.trail__item_text}>
                                        {text}
                                    </p>}
                                </div>
                                <img
                                    className={styles.trail__item_img}
                                    src={imgs[imgName]}
                                    alt={imgName}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Trail
