import React from "react";
import PropTypes from "prop-types";
import styles from "./Text.module.scss";
import classNames from "classnames";

const Text = ({ children, variant = "body16", className, isBold }) => {
  return <p className={classNames([styles[variant], {[styles.bold]: isBold}, className])}>{children}</p>;
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["body18", "body16", "body14", "body12"]),
};

export default Text;
