import React, { memo } from 'react'
import Title from 'components/UI/Title/Title'
import Text from 'components/UI/Text/Text'
import styles from './Header.module.scss'
import { useSelector } from "react-redux";
import { selectInfo, selectItemData } from "store/selectors/visaSteps";
import { ReactSVG } from "react-svg";
import classNames from 'classnames'

function formatDateWithDays(daysToAdd) {
    const date = new Date();

    date.setDate(date.getDate() + daysToAdd);

    const daysOfWeek = ["воскресения", "понедельника", "вторника", "среды", "четверга", "пятницы", "субботы"];
    const months = [
        "января", "февраля", "марта", "апреля", "мая", "июня",
        "июля", "августа", "сентября", "октября", "ноября", "декабря"
    ];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek}, ${day} ${month} ${year} г.`;
}

const Header = memo(() => {
    const { titleIcon, title, subtitleIcon, subtitle } = useSelector(selectItemData);
    const { tariff } = useSelector(selectInfo)

    return (
        <div className={styles.wrapper}>
            <div className={classNames([styles.text])}>
                {titleIcon && <ReactSVG src={titleIcon} width={24} height={24} />}
                {title && <Title Tag='h4'>{title}</Title>}
            </div>
            {(subtitleIcon || subtitle) && <div className={styles.text}>
                {subtitleIcon && <ReactSVG src={subtitleIcon} width={20} height={20} />}
                {subtitle && <Text variant='body16'>{tariff?.time ? subtitle.replace('DATE', formatDateWithDays(tariff.time)) : subtitle.replace('DATE', " ...")}</Text>}
            </div>}
        </div>
    )
})

export default Header
