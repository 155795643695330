
import { isDateInTheFuture } from 'utils/dateFn';

export const validate = (values, skipFields, citizenship) => {
    const errors = {};

    if (!skipFields) {
        /*if (!values.documentType) {
            errors.documentType = 'Тип документа обязателен';
        }*/
        if (!values.documentNumber) {
            errors.documentNumber = 'Номер документа обязателен';
        }
        if (!values.expiryDate) {
            errors.expiryDate = 'Срок действия документа обязателен';
        }
        else if (!isDateInTheFuture(values.expiryDate)){
            errors.expiryDate = 'Срок действия документа должен быть корректным'
        }

        if (citizenship?.code === 'RU' && values.documentType === 'FOREIGN' && !values.expiryDate){
            errors.expiryDate = "Поле обязательно";
        }
        if ((citizenship?.code === 'RU' || values.documentType === 'FOREIGN') && !/^[0-9]{9}$/.test(values.documentNumber)){
            errors.documentNumber = "Номер документа должен состоять из 9 цифр";
        }
    }

    return errors;
};
