export const isValidDate = (day, month, year) => {
    const date = new Date(year, month - 1, day);
    return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
    );
};

export const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split('.').map(Number);
    const date = new Date(year, month - 1, day);
    const yearFormatted = date.getFullYear();
    const monthFormatted = (date.getMonth() + 1).toString().padStart(2, '0');
    const dayFormatted = date.getDate().toString().padStart(2, '0');

    return `${yearFormatted}-${monthFormatted}-${dayFormatted}`;
};

export const isDateInTheFuture = (date) => {
    const [day, month, year] = date.split('.').map(Number);

    if (!isValidDate(day, month, year)) return false;

    const inputDate = new Date(year, month - 1, day);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return inputDate >= today;
};