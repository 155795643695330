import styles from "./Payment.module.scss";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import {ReactSVG} from "react-svg";
import file from "../../../assets/icons/fileComplete.svg";
import clock from "../../../assets/icons/smallClock.svg";
import {useEffect, useState} from "react";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import {useSelector} from "react-redux";
import {selectCurrency} from "store/selectors/header";
import {BASE_URL} from "api/constants";
import {
    getPassengerAmountString,
    getTariffPrice,
    getTariffsFlight,
} from "utils/tariffService";
import {selectTariffs} from "store/selectors/catalog";

const paymentCurrency = {
    RUB: "RUB",
    USD: "USD",
};

const Payment = ({
                     orderId,
                     orderData,

                     countryTo,
                     citizenship,
                     price,
                     arrival_date,
                     departure_date,
                     passangerCount,
                     paymentError,
                     isErrors,
                 }) => {
    const tariffs = useSelector(selectTariffs);
    const [termT, setTermT] = useState(0);
    const [priceT, setPriceT] = useState(0);

    const [totalPrice, setTotalPrice] = useState(0);
    const currentCurrency = useSelector(selectCurrency);
    const passengersAmount = getPassengerAmountString(passangerCount);

    const [isChecked, setIsChecked] = useState(true);

    /**
     * Установка цена тарифы
     */
    useEffect(() => {
        const tariffId = orderData?.tariff.id;
        if (tariffId) {
            const bookingTiketTariffs = getTariffsFlight(tariffs);

            /**
             * Текущий тариф
             */
            const currentTarrif = bookingTiketTariffs.find(
                (item) => item.id === tariffId
            );

            if (currentTarrif) {
                setTermT(currentTarrif?.term);
                const singlePrice = getTariffPrice(
                    currentCurrency?.code,
                    currentTarrif
                );
                setPriceT(singlePrice);
            }

        }
    }, [orderData, tariffs, currentCurrency]);

    useEffect(() => {
        let totalSum = 0;
        /**Кол-во пассажиров */
        totalSum += priceT * passangerCount;
        totalSum = orderData?.price;
        setTotalPrice(totalSum);
    }, [termT, priceT]);

    const redirectToPaymentTinkoff = (values, errors) => {
        window.location.replace(
            `${BASE_URL}payments/tinkoff/redirect?id=` + orderId + `&order_type=2`
        );
    };

    const redirectToPaymentCloud = (values, errors) => {
        window.location.replace(
            `${BASE_URL}payments/cloudpayments/redirect?id=` + orderId + `&order_type=2`
        );
    };
    return (
        <aside className={styles.payment}>
            <h2 className={styles.payment__title}>Ваше бронирование</h2>
            <div className={styles.payment__items}>
                <div className={styles.payment__item}>
                    <div className={styles.payment__item_icon}>
                        <ReactSVG src={file}/>
                    </div>
                    <div className={styles.payment__item_text}>
                        <p className={styles.payment__item_title}>
                            Информация
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            {passangerCount} {passengersAmount}
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            <>
                                гражданство: <b>{citizenship}</b> Поездка в страну: <b>{countryTo}</b>
                            </>
                        </p>
                    </div>
                    <div className={styles.payment__item_price}>
                        {/*<p>*/}
                        {/*    {priceT} {currentCurrency?.symbol} x{" "}*/}
                        {/*</p>*/}
                    </div>
                </div>
                <div className={styles.payment__item}>
                    <div className={styles.payment__item_icon}>
                        <ReactSVG src={clock}/>
                    </div>
                    <div className={styles.payment__item_text}>
                        <p className={styles.payment__item_title}>
                            Поездка запланирована на:
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            {arrival_date} - {departure_date}
                        </p>
                    </div>
                </div>


            </div>
            <div className={styles.payment__divider}>&nbsp;</div>
            <div className={styles.payment__sum}>
                <p className={styles.payment__sum_title}>Итого</p>
                <p className={styles.payment__sum_cost}>
                    {totalPrice} {currentCurrency?.symbol}
                </p>
            </div>
            {currentCurrency?.code == paymentCurrency.RUB && (
                <>
                    <CustomButton
                        classes={styles.payment__sum_btn}
                        primary={true}
                        fullWidth={true}
                        onClick={redirectToPaymentTinkoff}
                        type={"submit"}
                        disabled={!isChecked}
                    >
                        К оплате
                    </CustomButton>
                    <div className={styles.payment__title5}>
                        *Для оплаты иностранной картой выберите валюту “USD“
                    </div>
                </>
            )}
            {currentCurrency?.code == paymentCurrency.USD && (
                <>
                    <CustomButton
                        classes={styles.payment__sum_btn}
                        primary={true}
                        fullWidth={true}
                        onClick={redirectToPaymentCloud}
                        type={"submit"}
                        disabled={!isChecked}
                    >
                        К оплате в USD
                    </CustomButton>
                    <div className={styles.payment__title5}>
                        * Для оплаты картой РФ выберите валюту “RUB“
                    </div>
                </>
            )}

            {isErrors && (
                <div className={styles.warning}>
                    <ReactSVG src={WarningIcon}/>
                    <p>Проверьте правильность заполнения всех полей</p>
                </div>
            )}
            {paymentError && (
                <div className={styles.warning}>
                    <ReactSVG src={WarningIcon}/>
                    <p>{paymentError}</p>
                </div>
            )}
        </aside>
    );
};

export default Payment;
