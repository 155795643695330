import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "api";
import {BASE_URL} from "../api/constants";

const devApi = (args) =>
    createApi({
        // refetchOnFocus: true,
        baseQuery: baseQuery({ baseUrl: BASE_URL }),
        credentials: 'include',
        ...args,
    });

export const visaApi = devApi({
    reducerPath: "visaApi",
    endpoints: (builder) => ({
        getCountries: builder.query({
            query: (country) => ({
                url: `countries`,
                method: "GET",
                params: country ? { country } : undefined
            }),
        }),
        checkVisa: builder.query({
            query: ({ citizenship, countryTo }) => ({
                url: `visa/check`,
                method: "GET",
                params: { citizenship, countryTo }
            }),
        }),
        orderVisa: builder.mutation({
            query: (body) => ({
                url: `visa/order`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Links"],
        }),
    }),
});

export const { useGetCountriesQuery, useCheckVisaQuery, useOrderVisaMutation } = visaApi;
