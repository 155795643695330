import React, {useEffect, useState, useMemo} from "react";
import styles from "./ApplicationProcessingStep.module.scss";
import ApplicationProcessingTimeItem from "components/UI/ApplicationProcessingTimeItem/ApplicationProcessingTimeItem";
import {
    FAILURE_PROTECTION,
    next,
    setInfo,
    setIsFormSubmitted,
    STATE_FEE,
} from "store/slices/visaSteps";
import {useDispatch, useSelector} from "react-redux";
import {selectInfo, selectStepData} from "store/selectors/visaSteps";
import {selectTariffs} from "store/selectors/catalog";
import {getTariffPrice, getTariffsFlight} from "utils/tariffService";
import {selectCurrency} from "store/selectors/header";

const ApplicationProcessingStep = () => {
    const {countryTo, citizenship, visaType, tariff} = useSelector(selectInfo);
    const currentCurrency = useSelector(selectCurrency);
    const tariffData = useSelector(selectTariffs);
    const dispatch = useDispatch();
    const {isFormSubmitted} = useSelector(selectStepData);

    const availableTariffs = useMemo(() => {
        const bookingTicketTariffs = getTariffsFlight(tariffData);
        return bookingTicketTariffs
            .filter(
                (item) =>
                    item.tariff_type === "VISA_ORDER" &&
                    citizenship.code === item.citizenship?.code &&
                    countryTo.code === item.country_to?.code &&
                    item?.visa_type?.name === visaType
            )
            .map((item) => ({
                id: item.id,
                title: item.name,
                time: item.term,
                imgSrc: item.icon,
                description: item.description,
                price: getTariffPrice(currentCurrency?.code, item),
            }))
            .sort((a, b) => a.price - b.price);
    }, [tariffData, citizenship, countryTo, visaType, currentCurrency]);

    const [checkedItem, setCheckedItem] = useState(tariff?.id);

    // Обновление выбранного тарифа
    useEffect(() => {
        const selectedTariff = availableTariffs.find(({id}) => id === checkedItem);
        if (selectedTariff) {
            dispatch(setInfo({tariff: selectedTariff}));
        }
    }, [checkedItem, availableTariffs, dispatch]);

    // Переход к следующему шагу
    useEffect(() => {
        if (isFormSubmitted) {
            dispatch(next());
            dispatch(setIsFormSubmitted(false));
        }
    }, [isFormSubmitted, dispatch]);

    return (
        <div className={styles.page}>
            <div className={styles.form}>
                {availableTariffs.map((tariff) => (
                    <ApplicationProcessingTimeItem
                        key={tariff.id}
                        isChecked={checkedItem === tariff.id}
                        setIsChecked={() => setCheckedItem(tariff.id)}
                        className={styles.card}
                        {...tariff}
                        currentCurrency={currentCurrency}
                        description={tariff?.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default ApplicationProcessingStep;
