import React from 'react'
import styles from './TitleBanner.module.scss'
import classNames from 'classnames'
import Title from 'components/UI/Title/Title'
import Text from 'components/UI/Text/Text'

const TitleBanner = ({ title, desc, imgSrc, imgAlt, isPrimary, isDark, className }) => {
    return (
        <div className={classNames(styles.banner, { [styles.primary]: isPrimary, [styles.dark]: isDark })}>
            {imgSrc && <div className={styles.imgWrapper}>
                <img className={styles.img} src={imgSrc} alt={imgAlt} />
            </div>}
            <div className={styles.content}>
                <Title Tag='h3' className={styles.title}>{title}</Title>
                <Text variant='body16' className={styles.desc}>{desc}</Text>
            </div>
        </div>
    )
}

export default TitleBanner