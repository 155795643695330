import styles from "./VisaPaymentSuccess.module.scss";
import SingleBreadCrumb from "../../../components/UI/SingleBreadCrumb/SingleBreadCrumb";
import TicketWayInfoWrapper from "../../Booking/TicketWayInfoWrapper/TicketWayInfoWrapper";
import {useSearchParams, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {useGetVisaOrderByIdQuery} from "../../../services/orders";
import FAQ from "../../BookingSuccess/FAQ/FAQ";
import Passengers from "../../BookingSuccess/Passengers/Passengers";
import NotPaid from "../../BookingSuccess/NotPaid/NotPaid";
import AboutPurchase from "../../BookingSuccess/AboutPurchase/AboutPurchase";
import Paid from "./Paid/Paid";
import {Helmet} from "react-helmet-async";

const VisaPaymentSuccess = () => {

    const params = useParams();
    const orderId = params.id;
    const {
        data: ordersQuery,
        error,
        isLoading,
        refetch,
        isFetching
    } = useGetVisaOrderByIdQuery({id: orderId}, {skip: !orderId});
    const [searchParams] = useSearchParams();

    const sumItems = [];
    const catalog = useSelector((state) => state.catalog.catalog);
    const citizenship = catalog?.countries;
    const passengerTypes = {"adults": "взрослый", "children": "ребёнок", "babies": "младенец"};


    const orderStatus = {
        "created": "Не оплачено",
        "payed": "Оплачено",
        "processing": "Оформляется",
        "finished": "Оформлено",
        "canceled": "Отменено",
        "expired": "Истекло"
    };

    let itineraryReceiptAmount = 0;
    ordersQuery?.data.passengers.forEach(function (elem) {

        itineraryReceiptAmount++;

    });

    const passengersContent = [];
    ordersQuery?.data.passengers.forEach(function (elem) {
        let passenger = {
            birthDate: elem.birthday,
            citizenship: citizenship ? citizenship[elem.country] : '',
            documentNumber: elem.document_number,
            name: elem.lastname + " " + elem.firstname,
            sex: elem.gender === "female" ? "Женский" : "Мужской",
            ticketType: passengerTypes[elem.type],
        }

        passengersContent.push(passenger);
    });


    let tariff = ordersQuery?.data.tariff;


    const linkTo = '/bookingPayment/' + orderId;


    return (
        <section className={styles.VisaPaymentSuccess}>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container">
                <div className={styles.VisaPaymentSuccess__wrapper}>
                    <SingleBreadCrumb title={"На главную"} path={"/"}/>

                    {searchParams.get('message') && searchParams.get('payment') == 'failed' && (
                        <>
                            <NotPaid linkTo={linkTo} message={searchParams.get('message')}/>
                        </>

                    )}


                    {ordersQuery
                        && ordersQuery?.data.order_status !== 'canceled'
                        // && (!ordersQuery?.data?.message || flightBookingFile)
                        && !searchParams.get('message')
                        && (
                            <>
                                <Paid
                                    email={ordersQuery?.data.email}
                                    country_to={ordersQuery?.data.country_to}
                                    //routeLinkFlight={flightLink}
                                    //routeLinkHotel={hotelLink}
                                    //flightBookingFile={flightBookingFile}
                                    //hotelBookingFile={hotelBookingFile}
                                    //isOrderWithHotel={isOrderWithHotel}
                                    //orderRenewal={orderRenewal}
                                    //flightToBookingExpiresAt={ordersQuery.data?.flight_to_booking_expires_at}
                                    tariff={ordersQuery.data?.tariff}
                                    orderId={orderId}
                                    //orderRenewalDay={ordersQuery.data?.order_renewal_day}
                                />
                            </>
                        )}

                    {ordersQuery && (
                        <AboutPurchase
                            status={orderStatus[ordersQuery?.data.order_status]}
                            date={ordersQuery?.data.order_start_booking}
                            orderNumber={"№" + ordersQuery?.data.order_number}
                            sum={ordersQuery?.data.price + " " + ordersQuery?.data.currency}
                            flightToPnr={ordersQuery?.data.flight_to_pnr}
                            flightFromPnr={ordersQuery?.data.flight_from_pnr}
                            flightToBookingExpiresAt={ordersQuery?.data.flight_to_booking_expires_at}
                            flightFromBookingExpiresAt={ordersQuery?.data.flight_from_booking_expires_at}
                            flightToAirUrl={ordersQuery?.data.flight_to_air_url}
                            flightFromAirUrl={ordersQuery?.data.flight_from_air_url}
                            passengersQuantity={passengersContent.length}
                        >
                            {sumItems?.map((item, i) => {
                                return (
                                    <p className={styles.sumItems} key={i}>
                                        {item}
                                    </p>
                                );
                            })}
                        </AboutPurchase>
                    )}


                    {ordersQuery && (
                        <>
                            <Passengers passengers={passengersContent}/>
                        </>
                    )}
                </div>
            </div>
            <FAQ
                title={"Вопросы и ответы"}
                type={'visa'}
            />
        </section>
    );
};

export default VisaPaymentSuccess;
