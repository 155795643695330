import TabsItems from "../../components/UI/Tabs/Tabs";
import style from "../../components/UI/Tabs/Tabs.module.scss";
import styles from "./MainPage.module.scss";
import PlaneIcon from "../../assets/icons/plane.svg";
import DocumentsIcon from "../../assets/icons/documents.svg";
import InsuranceIcon from "../../assets/icons/insurance.svg";
import OrangeStar from "../../assets/icons/orangeStar.svg";
import GreenStar from "../../assets/icons/greenStar.svg";
import ReliablySection from "../../widgets/ReliablySection/ReliablySection";
import { ReactSVG } from "react-svg";
import Сooperation from "./Сooperation/Сooperation";
import Booking from "./Booking/Booking";
import Trail from "../../widgets/Trail/Trail";
import ReviewsMain from "./ReviewsMain/ReviewsMain";
import Maximum from "./Maximum/Maximum";
import QA from "./QA/QA";
import Partner from "./Partner/Partner";
import SearchPanel from "./SearchPanel/SearchPanel";
import VisaPanel from "./VisaPanel/VisaPanel";
import classNames from "classnames";
import { useHidePage } from "../../hooks/useHidePage";
import { useState } from "react";
import PopularDestinations from "./PopularDestinations/PopularDestinations";

const items = [
    {
        key: "1",
        label: (
            <div className={style.tabs_item}>
                <ReactSVG src={PlaneIcon} />
                <p className={style.tabs_item_text}>
                    Авиабилеты и отели для визы
                </p>
            </div>
        ),
        children: <SearchPanel />,
    },
    {
        key: "2",
        label: (
            <div className={style.tabs_item}>
                <ReactSVG src={DocumentsIcon} />
                {/*<span className={style.status_text}>Скоро</span>*/}
                <p className={style.tabs_item_text}>Документы для визы</p>
            </div>
        ),
        children: <VisaPanel />,
    },
    {
        key: "3",
        label: (
            <div className={style.tabs_item}>
                <ReactSVG src={InsuranceIcon} />
                <span className={style.status_text}>Скоро</span>
                <p className={style.tabs_item_text}>Страхование за границей</p>
            </div>
        ),
        children: `Content of Tab Pane 3`,
        disabled: true,
    },
];

const mainSectionData = [
    {
        title: 'Бронирование авиабилетов для визы без оплаты',
        subtitle: 'Уже 10 000+ путешественников прошли паспортный контроль, оформили и продлили визу с помощью сервиса!'
    },
    {
        title: 'Безопасные и надежные путешествия по миру',
        subtitle: 'Тревел-сервис, который помогает путешественникам надежно и безопасно передвигаться по миру'
    },
    {
        title: '',
        subtitle: ''
    }
]

const MainPage = () => {
    const [pageNumber, setPageNumber] = useState(0);
    const hideMainPage = useHidePage();

    const content = [
        <>
            <ReliablySection pageNumber={pageNumber} />
            <Сooperation />
            <Booking />
            <Trail pageNumber={pageNumber} />
            <ReviewsMain />
            <Maximum />
            <QA />
            <Partner />
        </>,
        <>
            <ReliablySection pageNumber={pageNumber} />
            <PopularDestinations />
            <Trail pageNumber={pageNumber} />
            <ReviewsMain />
            <Partner />
        </>
    ]
    return (
        <div
            className={classNames(styles.main, {
                [styles["main__hidden"]]: hideMainPage,
            })}
        >
            <div className={styles.main__top}>
                <div className="container">
                    <div className={styles.main__wrapper}>
                        <h1 className={styles.main__title}>
                            {mainSectionData[pageNumber].title}
                        </h1>
                        <p className={styles.main__subtitle}>
                            {mainSectionData[pageNumber].subtitle}
                        </p>
                        <div className={styles.main__rating}>
                            <div className={styles.main__rating_leftSide}>
                                <div className={styles.main__rating_whiteStar}>
                                    <ReactSVG src={OrangeStar} />
                                </div>
                                <p className={styles.main__rating_rate}>4.8</p>
                            </div>
                            <div className={styles.main__rating_rightSide}>
                                <div className={styles.main__rating_greenStar}>
                                    <ReactSVG src={GreenStar} />
                                </div>
                                <a href="https://www.trustpilot.com/review/airsurfer.co" target="_blank" rel="noopener noreferrer">
                                    <p className={styles.main__rating_text}>
                                        Trustpilot
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div className={styles.main__tabs}>
                            <TabsItems items={items} setPageNumber={setPageNumber} />
                        </div>
                    </div>
                </div>
            </div>
            {content[pageNumber]}
        </div>
    );
};

export default MainPage;
