import { Radio } from 'components/UI/Radio/Radio'
import Card from 'components/UI/Card/Card'
import styles from './RadioCard.module.scss'

const RadioCard = ({ imgSrc, isChecked, setIsChecked, children, title, subtitle, subtitleNote }) => {
    return <div onClick={() => setIsChecked(prev => !prev)} style={{cursor: 'pointer'}}>
        <Card
            imgSrc={imgSrc}
            rightHeaderElement={<Radio checked={isChecked} />}
            className={{ [styles.checked]: isChecked }}
            title={title}
            subtitle={subtitle}
            subtitleNote={subtitleNote}
        >
            {children}
        </Card>
    </div>
}

export default RadioCard