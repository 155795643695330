import React, { useEffect } from 'react'
import CustomDatePicker from 'components/UI/CustomDatePicker/CustomDatePicker'
import styles from './TripDetailsStep.module.scss'
import CustomInput from "components/UI/Input/Input";
import CustomCheckbox from "components/UI/Checkbox/CustomCheckbox";
import Text from 'components/UI/Text/Text';
import Form from 'pages/VisaStepsPage/ui/Form/Form';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfo } from 'store/selectors/visaSteps';
import { setInfo } from 'store/slices/visaSteps';
import { useFormSubmitted } from 'pages/VisaStepsPage/hooks';
import { validate } from './validate';

const TripDetailsStep = () => {
    const { countryTo } = useSelector(selectInfo)
    const info = useSelector(selectInfo)
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            arrivalDate: info?.arrivalDate || '',
            departureDate: info?.departureDate || '',
            email: info?.email || '',
            updates: info?.updates || false,
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate
    });

    useEffect(() => {
        dispatch(setInfo(formik.values))
    }, [formik.values])

    useFormSubmitted(formik)

    return (
        <Form className={styles.form}>
            <div className={styles.formInputs}>
                <CustomDatePicker
                    label={`Когда вы прибываете в ${countryTo?.label}?`}
                    isAdaptiveLabel
                    value={formik.values.arrivalDate}
                    onChange={(date) => formik.setFieldValue('arrivalDate', date)}
                    error={formik.errors.arrivalDate}
                />
                <CustomDatePicker
                    label={`Когда вы покидаете ${countryTo?.label}?`}
                    isAdaptiveLabel
                    value={formik.values.departureDate}
                    onChange={(date) => formik.setFieldValue('departureDate', date)}
                    error={formik.errors.departureDate}
                />
                <CustomInput
                    type={'email'}
                    label='Адрес электронной почты'
                    placeholder={'johnsmith@gmail.com'}
                    isAdaptiveLabel
                    value={formik.values.email}
                    onChange={(val) => formik.setFieldValue('email', val)}
                    name="email"
                    error={formik.errors.email}
                />
            </div>
            <Text variant='body14' className={styles.note}>
                Мы используем эти данные для создания вашей учетной записи AirSurfer и отправки вам обновлений по вашему заявлению.
            </Text>
            <CustomCheckbox
                text={'Я хочу получать обновления AirSurfer, запуски продуктов и персонализированные предложения. Я могу отказаться в любое время.'}
                checked={formik.values.updates}
                onChange={(e) => formik.setFieldValue('updates', e.target.checked)}
            />
        </Form>
    );
};

export default TripDetailsStep;
