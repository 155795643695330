import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectStepData } from "store/selectors/visaSteps";
import { next, setIsFormSubmitted } from "store/slices/visaSteps";

export const useFormSubmitted = (formik) => {
    const dispatch = useDispatch()
    const { isFormSubmitted } = useSelector(selectStepData);

    useEffect(() => {
        if (isFormSubmitted) {
            formik.validateForm().then((errors) => {
                const isFormValid = Object.keys(errors).length === 0;
                if (isFormValid) {
                    dispatch(next())
                }
                dispatch(setIsFormSubmitted(false))
            });
        }
    }, [isFormSubmitted]);
}


export const useApplicantsFormSubmitted = (formik, clb) => {
    const dispatch = useDispatch()
    const { isFormSubmitted } = useSelector(selectStepData);

    useEffect(() => {
        if (isFormSubmitted) {
            formik.validateForm().then((errors) => {
                const isFormValid = errors.applicants.every(i => Object.keys(i).length === 0);
                if (isFormValid) {
                    clb?.()
                    dispatch(next())
                }
                dispatch(setIsFormSubmitted(false))
            });
        }
    }, [isFormSubmitted]);
}