import React from 'react';
import styles from './ProgressTracker.module.scss';
import classNames from 'classnames';
import Text from '../Text/Text';

const ProgressTracker = ({
  currentStepNumber,
  currentItemNumber,
  currentStepData,
  titles,
  onStepClick
}) => {

  return (
    <div className={styles.wrapper}>
      {titles.map((step, index) => (
        <div className={styles.step} key={index}>
          <div onClick={() => onStepClick(index)} className={styles.stepTitle}>
            <Text
              variant='body18'
              className={classNames([styles.circle, { [styles.completed]: index < currentStepNumber }])}
            >
              {index + 1}
            </Text>
            <Text
              variant='body18'
              isBold
              className={styles.title}

            >
              {step}
            </Text>
          </div>
          {index < titles.length - 1 && (
            <div className={styles.progressLine}>
              <div
                className={classNames([styles.progressFill, { [styles.completed]: index < currentStepNumber }])}
                style={{
                  width: `${index < currentStepNumber - 1 ? 100 : Math.round(currentItemNumber ? currentItemNumber / currentStepData.length * 100 : 0)}%`,
                }}
              ></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressTracker;