import {Accordion} from "../../../ui-kit/Accordion/Accordion";
import styles from "./FAQ.module.scss";
import {BASE_URL_FRONT} from "../../../api/constants";

const FAQ = ({title = "FAQ", type = "sucess"}) => {
    let  faqContent = []
    if (type === "sucess") {
        faqContent =

            [
                {
                    title: "Что такое код бронирования (PNR)?",
                    content:
                        "Код бронирования, или PNR — это уникальный код, шести- или пятизначная последовательность букв и цифр. По данному коду сотрудник аэропорта, авиакомпании или агента может найти информацию по вашим билетам.",
                },
                {
                    title: "Что такое код-шеринговый рейс?",
                    content:
                        "Код-шеринговый рейс — это когда несколько авиакомпаний работают вместе, чтобы предложить пассажирам больше маршрутов и удобств. Вы бронируете билет у одной или нескольких авиакомпаний, но сам рейс выполняется другой авиакомпанией. Это значит, что вы можете лететь дальше с удобной пересадкой, не беспокоясь о разных бронированиях. Имейте в виду, что правила и услуги на борту могут немного различаться в зависимости от авиакомпании, выполняющей рейс.",
                },
                {
                    title: "Как выглядит пример маршрутной квитанции и брони отеля?",
                    content: (
                        <>
                            Маршрутные квитанции могут выглядеть по-разному.
                            При этом они всегда содержат следующие сведения:{" "}
                            <br/>
                            • имя пассажира, <br/>
                            • название авиакомпании, <br/>
                            • название агентства, выписавшего билет, <br/>
                            • код брони, на основании которой был выписан
                            билет, <br/>
                            • номер паспорта пассажира, <br/>
                            • дату рождения пассажира, <br/>
                            • дату выписки билета, <br/>• город вылета (FROM)
                            и город прибытия (TO), <br/>
                            • номер рейса, <br/>• класс бронирования (обычно
                            первый класс обозначается буквами F, P, A; бизнес
                            – J, C, D, I, Z; эконом – W, S, Y, B, H, K, L, M,
                            N, Q, T, V, X) <br/>
                            • дату и время вылета, <br/>
                            • норму провоза багажа, <br/>
                            • стоимость авиабилета. <br/>
                            <a
                                className={styles.qa_link}
                                href={
                                    BASE_URL_FRONT +
                                    "AirSurfer_Flight_Example.pdf"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Пример брони в PDF
                            </a>
                        </>
                    ),
                },
                {
                    title: "Как мне проверить, что мое бронирование авиабилета действительно?",
                    content: (
                        <>
                            Вы можете проверить билет прямо на сайте
                            авиакомпании по номеру бронирования (PNR-коду) и
                            фамилии пассажира, которые указаны в маршрутной
                            квитанции. Ссылка на сайт авиакомпании доступна на
                            странице успешного заказа в столбце “Проверка“.
                        </>
                    ),
                },
                // {
                //     title: "Я не могу найти свое бронирование авиабилета на сайте авиакомпании. Что мне делать?",
                //     content: "Обратите внимание на то, что на сайтах некоторых авиакомпаний можно просматривать только заказы," +
                //         " сделанные непосредственно на данных сайтах. Для проверки бронирования обратитесь в колл-центр " +
                //         "авиакомпании с номером брони или напишите в поддержку AirSurfer."
                // },
                {
                    title: "Как долго ждать получения маршрутной квитанции после бронирования?",
                    content:
                        "Обработка заказа происходит мгновенно и включает в себя получение маршрутной квитанции и" +
                        " отправка письма с маршрутной квитанцией на почту, указанную при оформлении. " +
                        "В редких случаях время оформления брони авиабилета занимает до 10 минут в зависимости от " +
                        "длительности подтверждения бронирования авиакомпанией. Время оформления брони отеля занимает до 1 часа. ",
                },
                {
                    title: "Как долго действует бронирование авиабилета?",
                    content:
                        "Срок действия - до 28 дней. Бронь авиабилета действительна 3 дня, 5-7 дней, 10-14 дней или 20-28 дней на выбор.",
                },
                {
                    title: "Могу ли я продлить текущее бронирование авиабилета?",
                    content:
                        "К сожалению, нет. Данная опция не предусмотрена ни в одной авиакомпании.  ",
                },
                {
                    title: "Когда мне следует оформить бронирование авиабилета?",
                    content:
                        "Советуем оформлять бронь билетов за один день до подачи заявления на визу или до предъявления при прохождении пограничного контроля. ",
                },
                {
                    title: "Нужно ли мне самостоятельно запрашивать отмену бронирования авиабилета?",
                    content:
                        "Бронирование авиабилета отменяется автоматически по истечению времени. С Вашей стороны никаких дополнительных действий не требуется.",
                },
            ];
    } else if (type === "visa") {
        faqContent = [
            {
                title: "Могу ли я отменить бронирование и вернуть деньги?",
                content: "После оформления бронирования, отменить его и получить возмещение невозможно.",
            },
            {
                title: "Можно ли забронировать билет и оплатить позже?",
                content: "Нет, приступить к оплате необходимо сразу после оформления заказа.",
            }
        ];
    } else {
        faqContent = [
            {
                title: "Что такое код бронирования (PNR)?",
                content:
                    "Код бронирования, или PNR — это уникальный код, шести- или пятизначная последовательность букв и цифр. По данному коду сотрудник аэропорта, авиакомпании или агента может найти информацию по вашим билетам.",
            },
            {
                title: "Как выглядит пример маршрутной квитанции и брони отеля?",
                content: (
                    <>
                        Маршрутные квитанции могут выглядеть по-разному.
                        При этом они всегда содержат следующие сведения:{" "}
                        <br/>
                        • имя пассажира, <br/>
                        • название авиакомпании, <br/>
                        • название агентства, выписавшего билет, <br/>
                        • код брони, на основании которой был выписан
                        билет, <br/>
                        • номер паспорта пассажира, <br/>
                        • дату рождения пассажира, <br/>
                        • дату выписки билета, <br/>• город вылета (FROM)
                        и город прибытия (TO), <br/>
                        • номер рейса, <br/>• класс бронирования (обычно
                        первый класс обозначается буквами F, P, A; бизнес
                        – J, C, D, I, Z; эконом – W, S, Y, B, H, K, L, M,
                        N, Q, T, V, X) <br/>
                        • дату и время вылета, <br/>
                        • норму провоза багажа, <br/>
                        • стоимость авиабилета. <br/>
                        <a
                            className={styles.qa_link}
                            href={
                                BASE_URL_FRONT +
                                "AirSurfer_Flight_Example.pdf"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Пример брони в PDF
                        </a>
                    </>
                ),
            },
            {
                title: "Как долго действует бронирование авиабилета?",
                content:
                    "Срок действия - до 28 дней. Бронь авиабилета действительна 3 дня, 5-7 дней, 10-14 дней или 20-28 дней на выбор.",
            },
            {
                title: "Когда мне следует оформить бронирование авиабилета?",
                content:
                    "Советуем оформлять бронь билетов за один день до подачи заявления на визу или до предъявления при прохождении пограничного контроля. ",
            },
            {
                title: "Могу ли я изменить оформленное бронирование?",
                content:
                    "Сервис AirSurfer делает моментальную бронь, поэтому оформленное бронирование изменить нельзя. Если Вам потребуются какие-либо изменения, Вам нужно будет сделать новое бронирование.",
            },
            {
                title: "Могу ли я отменить бронирование и вернуть деньги?",
                content:
                    "После оформления бронирования, отменить его и получить возмещение невозможно.",
            },
            {
                title: "Можно ли забронировать билет и оплатить позже?",
                content:
                    "Нет, приступить к оплате необходимо сразу после оформления заказа. ",
            },
        ];
    }
    return (
        <section className={styles.qa}>
            <div className="container">
                <div className={styles.qa__wrapper}>
                    <h2 className={styles.qa__title}>{title}</h2>
                    <div className={styles.qa__block}>
                        <Accordion accordionItems={faqContent}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
