import { ReactSVG } from "react-svg";
import styles from "./ReliablySection.module.scss";
import useMainPageReliablySection from "./useMainPageReliablySection";
import React from "react";


const ReliablySection = ({ pageNumber }) => {
    const { title, items: reliablyContent } = useMainPageReliablySection(pageNumber)

    // const updatedContent = reliablyContent.map((item) => {
    //     // if (item.text.includes("Подробнее, как проверить.")) {
    //     //     const [beforeText, afterText] = item.text.split(
    //     //         "Подробнее, как проверить."
    //     //     )
    //     //
    //     //     return {
    //     //         ...item,
    //     //         text: beforeText,
    //     //         linkText: afterText,
    //     //         link: "/faq",
    //     //     }
    //     // }
    //     return item;
    // });

    if (!reliablyContent) return

    return (
        <section className={styles.reliably}>
            <div className="container">
                <div className={styles.reliably__wrapper}>
                    <h2 className={styles.reliably__title}>
                        {title.map((text, i) => <React.Fragment key={text}>{i % 2 ? <span>{text}</span> : text}</React.Fragment>)}
                    </h2>
                    <div className={styles.reliably__items}>
                        {reliablyContent.map((item) => (
                            <div
                                className={styles.reliably__item}
                                key={item.key}
                            >
                                <div className={styles.reliably__item_img}>
                                    <ReactSVG src={item.img} width={40} height={40} />
                                </div>
                                <h2 className={styles.reliably__item_title}>
                                    {item.title}
                                </h2>
                                {item.link ? (
                                    <>
                                        <p
                                            className={
                                                styles.reliably__item_text
                                            }
                                        >
                                            {item.text}
                                            <br />
                                            <a
                                                className={
                                                    styles.reliably__item_link
                                                }
                                                href={item.link}
                                            >
                                                Подробнее, как проверить.
                                            </a>
                                        </p>
                                    </>
                                ) : (
                                    <p className={styles.reliably__item_text}>
                                        {item.text}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReliablySection;
