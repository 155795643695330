import styles from "./Maximum.module.scss";
import plane from "../../../assets/icons/plane-maximum.png";
import plane2 from "../../../assets/icons/plane2-maximum.png";
import pro from "../../../assets/icons/pro-maximum.png";
import orangeCheckmark from "../../../assets/icons/orangeCheckmark.svg";
import orangePlane from "../../../assets/icons/orangePlane.svg";
import orangeHouse from "../../../assets/icons/orangeHouse.svg";
import {ReactSVG} from "react-svg";
import clsx from "clsx";
import {BASE_URL_FRONT} from "../../../api/constants";
import {useSelector} from "react-redux";
import {selectTariffs} from "store/selectors/catalog";
import {getTariffsFlight, getTariffsHotel, getTariffPrice} from "utils/tariffService";
import {selectCurrency} from "store/selectors/header";

const Maximum = ({isProducts}) => {
    const tariffs = useSelector(selectTariffs);
    const currentCurrency = useSelector(selectCurrency);
    const bookingTiketTariffs = getTariffsFlight(tariffs)
    const bookingHotelsTariffs = getTariffsHotel(tariffs)
    return (
        <section
            className={clsx(styles.maximum, {
                [styles.maximum__products]: isProducts,
            })}
        >
            <div className="container">
                <div className={styles.maximum__wrapper}>
                    <h2 className={styles.maximum__title}>
                        Получайте максимум от <span>AirSurfer</span>
                    </h2>
                    <p className={styles.maximum__subtitle}>
                        При оформлении бронирования выберите нужный тариф, чтобы
                        увеличить срок действия маршрутной квитанции или
                        забронировать отель
                    </p>
                    <div className={styles.maximum__top}>
                        <ReactSVG src={orangePlane}/>
                        <h3 className={styles.maximum__receptTitle}>
                            Бронь авиабилета
                        </h3>
                    </div>
                    <div className={styles.maximum__recept}>
                        {bookingTiketTariffs.map((item) => {
                            if (item.tariff_type === 'FLIGHT_ORDER')
                                return (
                                    <div className={styles.maximum__item}>
                                        <div className={styles.maximum__item_top}>
                                            <img src={item.icon} alt="plane"/>
                                        </div>
                                        <h4 className={styles.maximum__item_title}>
                                            {item.name}
                                        </h4>
                                        <p className={styles.maximum__item_cost}>
                                            {getTariffPrice(
                                                currentCurrency?.code,
                                                item
                                            )}{" "}
                                            {currentCurrency?.symbol}
                                        </p>
                                        <p
                                            className={
                                                styles.maximum__item_description
                                            }
                                        >
                                            за пассажира
                                        </p>
                                        <div
                                            className={styles.maximum__item_line}
                                        ></div>
                                        <div
                                            className={styles.maximum__list}
                                            dangerouslySetInnerHTML={{
                                                __html: item.description,
                                            }}
                                        />
                                        <a
                                            href={item.example_pdf}
                                            className={styles.maximum__list_href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span>Пример брони в PDF</span>
                                        </a>
                                    </div>
                                );
                        })}
                    </div>
                    <div
                        className={`${styles.maximum__top} ${styles.maximum__margin}`}
                    >
                        <ReactSVG src={orangeHouse}/>
                        <h3 className={styles.maximum__receptTitle}>
                            Бронь отеля
                        </h3>
                    </div>
                    <div className={styles.maximum__recept}>
                        {bookingHotelsTariffs.map((item) => {
                            return (
                                <div className={styles.maximum__item}>
                                    <div className={styles.maximum__item_top}>
                                        <img src={item.icon} alt="plane"/>
                                    </div>
                                    <h4 className={styles.maximum__item_title}>
                                        {item.name}
                                    </h4>
                                    <p className={styles.maximum__item_cost}>
                                        {getTariffPrice(
                                            currentCurrency?.code,
                                            item
                                        )}{" "}
                                        {currentCurrency?.symbol}
                                    </p>
                                    <p
                                        className={
                                            styles.maximum__item_description
                                        }
                                    >
                                        за пассажира
                                    </p>
                                    <div
                                        className={styles.maximum__item_line}
                                    ></div>
                                    <div
                                        className={styles.maximum__list}
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    />
                                    <a
                                        href={item.example_pdf}
                                        className={styles.maximum__list_href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span>Пример брони в PDF</span>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Maximum;
