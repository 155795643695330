import React from 'react'
import styles from './Form.module.scss'
import classNames from 'classnames'

const Form = ({children, className}) => {
    return (
        <div className={classNames([styles.form, className])}>
           {children}
        </div>
    )
}

export default Form