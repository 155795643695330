import React from "react";
import PropTypes from "prop-types";
import styles from "./Text.module.scss";
import classNames from "classnames";

const TextVisaTatiff = ({ children, variant = "body16", className, isBold, dangerouslySetInnerHTML }) => {
    if (dangerouslySetInnerHTML) {
        return (
            <p
                className={classNames([styles[variant], { [styles.bold]: isBold }, className])}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            />
        );
    }

    return (
        <p className={classNames([styles[variant], { [styles.bold]: isBold }, className])}>
            {children}
        </p>
    );
};

TextVisaTatiff.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(["body18", "body16", "body14", "body12"]),
    className: PropTypes.string,
    isBold: PropTypes.bool,
    dangerouslySetInnerHTML: PropTypes.shape({
        __html: PropTypes.string.isRequired,
    }),
};

TextVisaTatiff.defaultProps = {
    children: null,
    className: "",
    isBold: false,
    dangerouslySetInnerHTML: null,
};

export default TextVisaTatiff;
