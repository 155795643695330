import { useFormik } from "formik";
import styles from "./ApplicantStep.module.scss";
import CustomInput from "../../../../components/UI/Input/Input";
import CustomDatePicker from "../../../../components/UI/CustomDatePicker/CustomDatePicker";
import VisaDropdownCountry from "components/UI/VisaDropdownCountry/VisaDropdownCountry";
import RadioGender from "components/UI/RadioGender/RadioGender";
import CustomButton from "components/UI/CustomButton/CustomButton";
import { filterSelectWithCountryName } from "pages/Booking/Customer/Customer";
import trash from "assets/icons/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectInfo } from "store/selectors/visaSteps";
import Form from "pages/VisaStepsPage/ui/Form/Form";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import profile from 'assets/icons/profile.svg'
import Title from "components/UI/Title/Title";
import { setInfo } from "store/slices/visaSteps";
import { useEffect } from "react";
import { useApplicantsFormSubmitted } from "pages/VisaStepsPage/hooks";
import Text from "components/UI/Text/Text";
import { validate } from "./validate";

const ApplicantStep = () => {
    const dispatch = useDispatch()
    const { citizenship } = useSelector(selectInfo);
    const { passengers } = useSelector(selectInfo)

    const defaultApplicant = {
        id: Date.now(),
        birthCountry: citizenship,
        residenceCountry: citizenship,
        firstName: "",
        lastName: "",
        birthDate: "",
        gender: "",
    }

    const formik = useFormik({
        initialValues: {
            applicants: passengers?.length ? passengers : [defaultApplicant],
        },
        validate,
        validateOnChange: false,
        validateOnBlur: false
    });

    const addApplicant = () => {
        formik.setFieldValue("applicants", [
            ...formik.values.applicants,
            defaultApplicant,
        ], false);
    };

    const removeApplicant = (index) => {
        const newApplicants = formik.values.applicants.filter((_, i) => i !== index);
        formik.setFieldValue("applicants", newApplicants, false);
    };

    useApplicantsFormSubmitted(formik)
    useEffect(() => {
        dispatch(setInfo({ passengers: formik.values.applicants }))
    }, [formik.values])

    return (
        <div className={styles.forms}>
            {formik.values.applicants.map((applicant, index) => (
                <div>
                    {index ? <div className={classNames([styles.title])}>
                        <div className={classNames([styles.text])}>
                            <ReactSVG src={profile} width={24} height={24} />
                            <Title Tag='h4'>{index + 1} заявитель</Title>
                        </div>
                        <ReactSVG className={styles.trash} onClick={() => removeApplicant(index)} src={trash} width={24} height={24} />
                    </div> : <></>}

                    <Form className={styles.form} key={applicant.id}>
                        <VisaDropdownCountry
                            label={"Страна рождения"}
                            value={applicant.birthCountry}
                            onChange={(val) => formik.setFieldValue(`applicants[${index}].birthCountry`, val)}
                            onSearch={(val) => formik.setFieldValue(`applicants[${index}].birthCountry`, val)}
                            showSearch
                            filterOption={filterSelectWithCountryName}
                            error={formik.errors.applicants?.[index]?.birthCountry}
                        />
                        <VisaDropdownCountry
                            label={"Страна проживания"}
                            value={applicant.residenceCountry}
                            onChange={(val) => formik.setFieldValue(`applicants[${index}].residenceCountry`, val)}
                            onSearch={(val) => formik.setFieldValue(`applicants[${index}].residenceCountry`, val)}
                            showSearch
                            filterOption={filterSelectWithCountryName}
                            error={formik.errors.applicants?.[index]?.residenceCountry}
                        />
                        <div>
                            <CustomInput
                                type="text"
                                placeholder="Имя"
                                value={applicant.firstName}
                                onChange={(e) => formik.setFieldValue(`applicants[${index}].firstName`, e.toUpperCase())}
                                error={formik.errors.applicants?.[index]?.firstName}
                            />
                            <Text variant="body14" className={styles.note}>Только на латинице, например IVAN IVANOV</Text>
                        </div>
                        <CustomInput
                            type="text"
                            placeholder="Фамилия"
                            value={applicant.lastName}
                            onChange={(e) => formik.setFieldValue(`applicants[${index}].lastName`, e.toUpperCase())}
                            error={formik.errors.applicants?.[index]?.lastName}
                        />
                        <CustomDatePicker
                            placeholder="Дата рождения"
                            value={applicant.birthDate}
                            onChange={(date) => formik.setFieldValue(`applicants[${index}].birthDate`, date)}
                            error={formik.errors.applicants?.[index]?.birthDate}
                        />
                        <RadioGender
                            touched={true}
                            value={applicant.gender}
                            onChange={(val) => formik.setFieldValue(`applicants[${index}].gender`, val)}
                            errors={formik.errors.applicants?.[index]?.gender}
                            checkedMale={applicant.gender === "Мужской"}
                            valueMale={"Мужской"}
                            valueFemale="Женский"
                            checkedFemale={applicant.gender === "Женский"}
                            setFieldValue={(name, val) => formik.setFieldValue(`applicants[${index}].gender`, val)}
                        />
                    </Form>
                </div>
            ))}
            <CustomButton primary onClick={addApplicant}>
                Добавить заявителя
            </CustomButton>
        </div>
    );
};

export default ApplicantStep;
